import { memo, useState } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import withAssetPrefix from '@/common/with-asset-prefix'

import IntersectionObserver from '../elements/intersection-observer'
import { ResponsiveSection, SectionContainer } from '../elements/section'
import media from '../styles/media'

import { ResponsiveBr } from './toolbox-section'

const Heading = styled.h2`
  font-family: sans-serif;
  font-weight: 500;
  color: #3a3a3a;
  margin: 0;

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out;
  }

  ${media.desktop`
    position: absolute;
    top: 240px;
    font-size: 52px;
    line-height: 68px;
    letter-spacing: -0.87px;
  `}

  ${media.phone`
    padding: 110px 0 0 0;
    font-size: 26px;
    line-height: 34px;
    letter-spacing: -0.5px;
    text-align: center;
  `}
`

const Subheading = styled.div`
  font-family: sans-serif;
  line-height: 30px;
  letter-spacing: -0.2833334px;
  color: #3a3a3a;

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 100ms;
  }

  ${media.desktop`
    font-size: 17px;
    line-height: 30px;
    letter-spacing: -0.28px;
    position: absolute;
    top: 396px;
    margin: 0;
  `}

  ${media.phone`
    margin: 12px 0 0 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.5px;
    text-align: center;
  `}
`

const ReviewContainerLeft = styled.div`
  position: absolute;

  ${media.desktop`
    top: 327px;
    right: 310px;
  `}

  ${media.phone`
    top: 369px;
    left: 20.5px;
  `}
`

const ReviewContainerRight = styled.div`
  position: absolute;

  ${media.desktop`
    top: 208px;
    right: 0px;
  `}

  ${media.phone`
    top: 304px;
    right: 20.5px;
  `}
`

const Review = styled.img<{ order: number }>`
  display: block;

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out ${({ order }) => order * 100 + 200}ms;
  }

  ${media.desktop`
    width: 290px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: 0 24px 38px 0 rgba(0, 0, 0, 0.18);
  `}

  ${media.phone`
    width: 154px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.18);
  `}
`

interface ReviewWithCompProps {
  in: boolean
  inTransition?: boolean
  order: number
  src: string
}

function ReviewWithTransition({
  in: inTransition,
  ...props
}: ReviewWithCompProps) {
  const { order } = props

  return (
    <CSSTransition
      in={inTransition}
      classNames="fade"
      className="fade-enter"
      timeout={700 + (order * 100 + 200)}
    >
      <Review {...props} />
    </CSSTransition>
  )
}

function Reviews() {
  const [active, setActive] = useState(false)

  return (
    <SectionContainer background="#f5f5f5">
      <ResponsiveSection desktop={{ height: 1000 }} phone={{ height: 700 }}>
        <IntersectionObserver
          active={active}
          onActivate={() => setActive(true)}
        >
          <CSSTransition
            in={active}
            classNames="fade"
            className="fade-enter"
            timeout={700}
          >
            <Heading>
              여행기와 <ResponsiveBr phone /> 리뷰를 <ResponsiveBr />
              활용하세요.
            </Heading>
          </CSSTransition>
        </IntersectionObserver>

        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={700}
        >
          <Subheading>
            먼저 다녀온 여행자의 리얼한 여행기와 리뷰에서
            <br />
            숨은 정보와 꿀팁을 확인하세요.
            <br />
            마음에 드는 일정은 내 일정에 담을 수 있어요.
          </Subheading>
        </CSSTransition>

        <ReviewContainerLeft>
          <ReviewWithTransition
            in={active}
            order={1}
            src={withAssetPrefix('/images/img_08_review_1@4x.png')}
          />
          <ReviewWithTransition
            in={active}
            order={2}
            src={withAssetPrefix('/images/img_08_review_2@4x.png')}
          />
          <ReviewWithTransition
            in={active}
            order={3}
            src={withAssetPrefix('/images/img_08_review_3@4x.png')}
          />
        </ReviewContainerLeft>

        <ReviewContainerRight>
          <ReviewWithTransition
            in={active}
            order={4}
            src={withAssetPrefix('/images/img_99_rounge_A_01_detail@4x.png')}
          />
          <ReviewWithTransition
            in={active}
            order={5}
            src={withAssetPrefix('/images/img_99_rounge_B_01_detail@4x.png')}
          />
        </ReviewContainerRight>
      </ResponsiveSection>
    </SectionContainer>
  )
}

const ReviewSection = memo(Reviews)

export default ReviewSection
