import { memo, useState } from 'react'
import styled from 'styled-components'
import { Responsive } from '@titicaca/core-elements'
import { CSSTransition } from 'react-transition-group'

import withAssetPrefix from '@/common/with-asset-prefix'

import IntersectionObserver from '../elements/intersection-observer'
import { ResponsiveSection, SectionContainer } from '../elements/section'
import Phone from '../elements/phone'
import media from '../styles/media'

import { ResponsiveBr } from './toolbox-section'

const Heading = styled.h2`
  font-family: sans-serif;
  font-weight: 500;
  letter-spacing: -1px;
  color: #3a3a3a;
  width: 100%;
  text-align: center;
  margin: 0;

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out;
  }

  ${media.desktop`
    position: absolute;
    font-size: 52px;
    line-height: 68px;
    top: 160px;
  `}

  ${media.phone`
    margin: 0;
    padding-top: 110px;
    font-size: 26px;
    line-height: 34px;
    letter-spacing: -0.43px;
  `}
`

const Subheading = styled.div`
  font-family: sans-serif;
  color: #3a3a3a;
  text-align: center;
  margin: 0;

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 100ms;
  }

  ${media.desktop`
    font-size: 17px;
    line-height: 30px;
    letter-spacing: -0.28px;
    position: absolute;
    top: 316px;
    width: 100%;
  `}

  ${media.phone`
    font-size: 15px;
    line-height: 24px;
    margin: 12px 0 0 0;
    letter-spacing: -0.5px;
  `}
`

const PhonesContainer = styled.div`
  text-align: center;
  position: relative;
  z-index: 0;

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 200ms;
  }

  .divider {
    display: inline-block;
  }

  ${media.desktop`
    font-size: 17px;
    letter-spacing: -0.2833334px;
    position: absolute;
    top: 451px;
    width: 100%;

    .divider {
      width: 40px;
    }
  `}

  ${media.phone`
    font-size: 14px;
    line-height: 24px;
    margin: 72px 0 0 0;
    letter-spacing: -0.5px;

    .divider {
      width: 20px;
    }
  `}
`

const Divider = styled.div`
  display: inline-block;

  ${media.desktop`
    width: 30px;
  `}

  ${media.phone`
    width: 8px;
  `}
`

function Reservation() {
  const [active, setActive] = useState(false)

  return (
    <SectionContainer background="#fafafa">
      <ResponsiveSection desktop={{ height: 1012 }} phone={{ height: 700 }}>
        <IntersectionObserver
          active={active}
          onActivate={() => setActive(true)}
        >
          <CSSTransition
            in={active}
            classNames="fade"
            className="fade-enter"
            timeout={700}
          >
            <Heading>
              여행에 필요한 모든 것,
              <br />한 번에 예약하세요
            </Heading>
          </CSSTransition>
        </IntersectionObserver>

        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={800}
        >
          <Subheading>
            항공부터 숙소, 투어, 티켓까지
            <br />
            예약 할수록 따라오는
            <ResponsiveBr phone /> 추가 할인 혜택도 함께 누리세요.
          </Subheading>
        </CSSTransition>

        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={900}
        >
          <PhonesContainer>
            <Responsive maxWidth={1141}>
              <Phone
                size="smallest"
                src={withAssetPrefix('/images/img_07_screen_0@4x.png')}
                absolutePosition={{ top: '13px', left: '30px' }}
              />
              <Phone
                zIndex={1}
                size="small"
                display="inline-block"
                src={withAssetPrefix('/images/img_07_screen_2@4x.png')}
              />
              <Phone
                size="smallest"
                src={withAssetPrefix('/images/img_07_screen_1@4x.png')}
                absolutePosition={{ top: '13px', right: '30px' }}
              />
            </Responsive>
            <Responsive minWidth={1142}>
              <Phone
                size="smallest"
                desktopSize="biggest"
                display="inline-block"
                src={withAssetPrefix('/images/img_07_screen_0@4x.png')}
              />
              <Divider />
              <Phone
                size="small"
                desktopSize="biggest"
                display="inline-block"
                src={withAssetPrefix('/images/img_07_screen_1@4x.png')}
              />
              <Divider />
              <Phone
                size="smallest"
                desktopSize="biggest"
                display="inline-block"
                src={withAssetPrefix('/images/img_07_screen_2@4x.png')}
              />
            </Responsive>
          </PhonesContainer>
        </CSSTransition>
      </ResponsiveSection>
    </SectionContainer>
  )
}

const ReservationSection = memo(Reservation)

export default ReservationSection
