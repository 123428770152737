import {
  css,
  CSSObject,
  FlattenSimpleInterpolation,
  SimpleInterpolation,
} from 'styled-components'

export const MIN_DESKTOP_WIDTH = 1142
export const MAX_PHONE_WIDTH = 1141

interface ScreenSize {
  [key: string]: {
    min?: number
    max?: number
  }
}

const SIZES: ScreenSize = {
  desktop: {
    min: MIN_DESKTOP_WIDTH,
  },
  phone: {
    max: MAX_PHONE_WIDTH,
  },
}

type MediaQueries = {
  [key in 'phone' | 'desktop']: (
    first: TemplateStringsArray | CSSObject,
    ...interpolations: SimpleInterpolation[]
  ) => FlattenSimpleInterpolation
}

const media = (Object.keys(SIZES) as Array<keyof typeof SIZES>).reduce(
  (accumulatedMedia: Partial<MediaQueries>, label) => {
    const { min, max } = SIZES[label]
    accumulatedMedia[label as 'phone' | 'desktop'] = (
      first,
      ...interpolations
    ) =>
      css`
        ${`
      @media ${generateMediaQuery({ min, max })} {
        ${css(first, interpolations).join('')};
      }
    `}
      `

    return accumulatedMedia
  },
  {} as Partial<MediaQueries>,
) as MediaQueries

function generateMediaQuery({
  min,
  max,
}: {
  min?: number
  max?: number
}): string {
  const minQuery = min && `(min-width: ${min}px)`
  const maxQuery = max && `(max-width: ${max}px)`

  return [minQuery, maxQuery].filter((v) => v).join(' and ')
}

export default media
