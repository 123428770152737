import { ComponentType, createContext, ReactNode, useState } from 'react'

const { Provider, Consumer } = createContext({})

interface HeaderContextProps {
  isPublic: boolean
  activeHeader: boolean
  activeSection9: boolean
  setActiveHeader(isIntersecting: boolean): void
  setActiveSection9(isIntersecting: boolean): void
}

export function HeaderContextProvider({ children }: { children: ReactNode }) {
  const [activeHeader, setActiveHeader] = useState(false)
  const [activeSection9, setActiveSection9] = useState(false)

  const state = {
    activeHeader,
    activeSection9,
  }

  const actions = {
    setActiveHeader,
    setActiveSection9,
  }

  return <Provider value={{ ...state, ...actions }}>{children}</Provider>
}

export function withHeaderContext<P extends HeaderContextProps>(
  WrappedComponent: ComponentType<P>,
): ComponentType<Omit<P, keyof HeaderContextProps>> {
  return function WithHeaderContext(props) {
    return (
      <Consumer>
        {(context) => <WrappedComponent {...({ ...props, ...context } as P)} />}
      </Consumer>
    )
  }
}
