import { get, HttpResponse } from '@titicaca/fetcher'

import { InventoryItem } from './types'

interface InventoryItemsResponse {
  items: InventoryItem[]
}

export async function fetchInventories(
  itemName: string,
): Promise<HttpResponse<InventoryItemsResponse>> {
  return get(`/api/inventories/${itemName}/items`)
}
