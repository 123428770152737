import { memo, useState } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import withAssetPrefix from '@/common/with-asset-prefix'

import { ResponsiveSection, SectionContainer } from '../elements/section'
import IntersectionObserver from '../elements/intersection-observer'
import media from '../styles/media'

const Heading = styled.h3`
  font-family: sans-serif;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  text-align: center;
  width: 100%;
  opacity: 0;
  transform: translateY(10px);

  &.fade-enter-done {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out;
  }

  ${media.desktop`
    padding-top: 182px;
    font-size: 28px;
  `}

  ${media.phone`
    padding-top: 158px;
    font-size: 20px;
  `}
`

const Logo = styled.h2<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  opacity: 0;
  transform: translateY(10px);

  &.fade-enter-done {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 100ms;
  }

  ${media.desktop`
    width: 310px;
    height: 333px;
    background-size: 310px 333px;
    margin: 30px auto;
  `}

  ${media.phone`
    width: 155px;
    height: 167px;
    background-size: 155px 167px;
    margin: 30px auto 20px;
  `}
`

const Description = styled.p`
  font-family: sans-serif;
  margin: 0;
  color: #ffffff;
  text-align: center;
  opacity: 0;
  transform: translateY(10px);

  &.fade-enter-done {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 200ms;
  }

  ${media.desktop`
    margin-bottom: 60px;
    font-size: 17px;
    line-height: 1.76;
    letter-spacing: -0.3px;
  `}

  ${media.phone`
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 1.71;
    letter-spacing: -0.5px;
  `}
`

const Button = styled.a`
  font-family: sans-serif;
  display: block;
  margin: 0 auto;
  border-radius: 30px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  color: #3666ff;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  opacity: 0;
  transform: translateY(10px);

  &.fade-enter-done {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 300ms;
  }

  ${media.desktop`
    width: 200px;
    font-size: 17px;
    line-height: 1.76;
    letter-spacing: -0.3px;
    padding: 15px 0;
  `}

  ${media.phone`
    width: 140px;
    padding: 11px 0px;
    font-size: 11.9px;
    line-height: 1.76;
    letter-spacing: -0.2px;
  `}
`

function Travelers() {
  const [active, setActive] = useState(false)

  return (
    <IntersectionObserver active={active} onActivate={() => setActive(true)}>
      <SectionContainer
        background={`url(${withAssetPrefix(
          '/images/group-section-8.svg',
        )}) #3666ff`}
      >
        <ResponsiveSection desktop={{ height: 1000 }} phone={{ height: 700 }}>
          <CSSTransition timeout={0} in={active} classNames="fade">
            <Heading>Recruit Now!</Heading>
          </CSSTransition>

          <CSSTransition timeout={0} in={active} classNames="fade">
            <Logo src={withAssetPrefix('/images/img-09-logo-program.png')} />
          </CSSTransition>
          <CSSTransition timeout={0} in={active} classNames="fade">
            <Description>
              즐겁게 여행을 준비하고,
              <br />
              추억을 나누기만 해도 쌓이는 트리플만의
              <br />
              놀라운 혜택을 지금 바로 누려보세요!
            </Description>
          </CSSTransition>
          <CSSTransition timeout={0} in={active} classNames="fade">
            <Button
              href={`https://triple.onelink.me/aZP6?pid=intro_web&af_dp=${encodeURIComponent(
                'triple:///my/mileage/intro',
              )}&af_web_dp=${encodeURIComponent(
                'https://play.google.com/store/apps/details?id=com.titicacacorp.triple',
              )}`}
            >
              자세히 보기
            </Button>
          </CSSTransition>
        </ResponsiveSection>
      </SectionContainer>
    </IntersectionObserver>
  )
}

const TravelersClubSection = memo(Travelers)

export default TravelersClubSection
