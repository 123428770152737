import { ComponentType, PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { Responsive, Container } from '@titicaca/core-elements'
import { useEventTrackingContext } from '@titicaca/react-contexts'

import withAssetPrefix from '@/common/with-asset-prefix'

interface Styles {
  width: number
  height: number
  padding?: {
    top?: number
    bottom?: number
    left?: number
    right?: number
  }
  absoulte?: {
    top?: number
    bottom?: number
    left?: number
    right?: number
  }
  active: boolean
  backgroundImageSrc?: string
  hoverImageSrc?: string
}

const ButtonContainer = styled.div`
  display: inline-block;
`

const RoundButton = styled.a<Styles>`
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #fff;
  font-weight: bold;
  transition: all 200ms ease-in-out;

  ${({ padding }) =>
    padding &&
    css`
      padding-top: ${padding.top || 0}px;
      padding-bottom: ${padding.bottom || 0}px;
      padding-left: ${padding.left || 0}px;
      padding-right: ${padding.right || 0}px;
    `};

  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `};

  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
      line-height: ${height}px;
      border-radius: ${height / 2}px;
    `};

  ${({ active }) =>
    active
      ? css`
          color: #3a3a3a;
          background: #fff;
        `
      : css`
          color: #ffffff;
        `}
`

const MarketLinkElement = styled.span<Styles>`
  display: inline-block;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  background-image: url(${({ backgroundImageSrc }) => backgroundImageSrc});
  background-size: ${({ width, height }) => `${width}px ${height}px`};
  opacity: 0.9;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  ${({ absoulte }) =>
    absoulte &&
    css`
      top: ${absoulte.top}%;
      left: ${absoulte.left}px;
    `}

  ${({ active, hoverImageSrc }) =>
    active &&
    hoverImageSrc &&
    css`
      background-image: url(${hoverImageSrc});
    `}
`

const SquareButton = styled.a`
  position: absolute;
  z-index: 1;
  bottom: -20px;
  left: 0;
  transition: all 200ms ease-in-out;
  width: 100%;
  padding: 14px 0;
  background-color: var(--color-blue);
  font-size: 15px;
  line-height: 22px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  letter-spacing: -0.25px;
  color: var(--color-white);
`

interface ButtonActive {
  active: boolean
}

function withHover(
  WrappedComponent: ComponentType<ButtonActive>,
): ComponentType {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return class WrappingComponent extends PureComponent<{}, ButtonActive> {
    public state = {
      active: false,
    }

    public render() {
      const { active } = this.state
      return (
        <ButtonContainer
          onMouseEnter={() =>
            this.setState({
              active: true,
            })
          }
          onMouseLeave={() =>
            this.setState({
              active: false,
            })
          }
        >
          <WrappedComponent active={active} />
        </ButtonContainer>
      )
    }
  }
}

function GooglePlayButton({ active }: ButtonActive) {
  const { trackEvent } = useEventTrackingContext()
  return (
    <Container>
      <Responsive inline maxWidth={1141}>
        <RoundButton
          width={126}
          height={36}
          active={active}
          href="http://play.google.com/store/apps/details?id=com.titicacacorp.triple"
          onClick={() => {
            trackEvent({
              ga: ['구글_플레이_트리플_앱_설치'],
            })
          }}
        >
          <MarketLinkElement
            width={13}
            height={14}
            active={active}
            absoulte={{ top: 48, left: 24 }}
            backgroundImageSrc={withAssetPrefix(
              '/images/google-play-icon@3x.png',
            )}
            hoverImageSrc={withAssetPrefix(
              '/images/google-play-icon-active@3x.png',
            )}
          />
          <MarketLinkElement
            width={62}
            height={12}
            absoulte={{ left: 42 }}
            active={active}
            backgroundImageSrc={withAssetPrefix('/images/google-play@3x.png')}
            hoverImageSrc={withAssetPrefix('/images/google-play-active@3x.png')}
          />
        </RoundButton>
      </Responsive>
      <Responsive inline minWidth={1142}>
        <RoundButton
          width={180}
          height={50}
          active={active}
          href="http://play.google.com/store/apps/details?id=com.titicacacorp.triple"
          onClick={() => {
            trackEvent({
              ga: ['구글_플레이_트리플_앱_설치'],
            })
          }}
        >
          <MarketLinkElement
            width={18}
            height={20}
            active={active}
            absoulte={{ top: 48, left: 34 }}
            backgroundImageSrc={withAssetPrefix(
              '/images/google-play-icon@3x.png',
            )}
            hoverImageSrc={withAssetPrefix(
              '/images/google-play-icon-active@3x.png',
            )}
          />
          <MarketLinkElement
            width={88}
            height={16}
            absoulte={{ left: 59 }}
            active={active}
            backgroundImageSrc={withAssetPrefix('/images/google-play@3x.png')}
            hoverImageSrc={withAssetPrefix('/images/google-play-active@3x.png')}
          />
        </RoundButton>
      </Responsive>
    </Container>
  )
}

function AppStoreButton({ active }: ButtonActive) {
  const { trackEvent } = useEventTrackingContext()
  return (
    <>
      <Responsive inline maxWidth={1141}>
        <RoundButton
          width={119}
          height={36}
          active={active}
          href="https://itunes.apple.com/app/id1225499481"
          onClick={() => {
            trackEvent({
              ga: ['앱_스토어_트리플_앱_설치'],
            })
          }}
        >
          <MarketLinkElement
            width={12}
            height={15}
            active={active}
            absoulte={{ top: 45, left: 27 }}
            backgroundImageSrc={withAssetPrefix(
              '/images/app-store-icon@3x.png',
            )}
            hoverImageSrc={withAssetPrefix(
              '/images/app-store-icon-active@3x.png',
            )}
          />
          <MarketLinkElement
            width={51}
            height={11}
            active={active}
            absoulte={{ left: 42 }}
            backgroundImageSrc={withAssetPrefix('/images/app-store@3x.png')}
            hoverImageSrc={withAssetPrefix('/images/app-store-active@3x.png')}
          />
        </RoundButton>
      </Responsive>
      <Responsive inline minWidth={1142}>
        <RoundButton
          width={170}
          height={50}
          active={active}
          href="https://itunes.apple.com/app/id1225499481"
          onClick={() => {
            trackEvent({
              ga: ['앱_스토어_트리플_앱_설치'],
            })
          }}
        >
          <MarketLinkElement
            width={17}
            height={21}
            active={active}
            absoulte={{ top: 45, left: 38 }}
            backgroundImageSrc={withAssetPrefix(
              '/images/app-store-icon@3x.png',
            )}
            hoverImageSrc={withAssetPrefix(
              '/images/app-store-icon-active@3x.png',
            )}
          />
          <MarketLinkElement
            width={73}
            height={16}
            absoulte={{ left: 60 }}
            active={active}
            backgroundImageSrc={withAssetPrefix('/images/app-store@3x.png')}
            hoverImageSrc={withAssetPrefix('/images/app-store-active@3x.png')}
          />
        </RoundButton>
      </Responsive>
    </>
  )
}

const CONVERSION_URL = `https://triple.onelink.me/aZP6?pid=intro_web&af_dp=${encodeURIComponent(
  'triple:///my/mileage/intro',
)}&af_web_dp=${encodeURIComponent(
  'https://play.google.com/store/apps/details?id=com.titicacacorp.triple',
)}`

function DownloadButton({ active }: ButtonActive) {
  const { trackEvent } = useEventTrackingContext()

  return (
    <Container>
      <Responsive inline maxWidth={1141}>
        <RoundButton
          width={126}
          height={36}
          active={active}
          href={CONVERSION_URL}
          onClick={() => {
            trackEvent({
              ga: ['트리플_앱_설치'],
            })
          }}
        >
          앱 설치하기
        </RoundButton>
      </Responsive>
      <Responsive inline minWidth={1142}>
        <RoundButton
          width={180}
          height={50}
          active={active}
          href={CONVERSION_URL}
          onClick={() => {
            trackEvent({
              ga: ['트리플_앱_설치'],
            })
          }}
        >
          앱 설치하기
        </RoundButton>
      </Responsive>
    </Container>
  )
}

export function DownloadAppButton() {
  const { trackEvent } = useEventTrackingContext()

  return (
    <Responsive maxWidth={1141} css={{ position: 'relative' }}>
      <SquareButton
        href={CONVERSION_URL}
        onClick={() => {
          trackEvent({
            ga: ['트리플_앱_설치'],
          })
        }}
      >
        앱 설치하기
      </SquareButton>
    </Responsive>
  )
}

export const GooglePlay = withHover(GooglePlayButton)
export const AppStore = withHover(AppStoreButton)
export const Download = withHover(DownloadButton)
