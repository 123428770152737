import { memo, useState } from 'react'
import styled from 'styled-components'
import { Responsive } from '@titicaca/core-elements'
import { CSSTransition } from 'react-transition-group'

import withAssetPrefix from '@/common/with-asset-prefix'

import { ResponsiveSection, SectionContainer } from '../elements/section'
import IntersectionObserver from '../elements/intersection-observer'
import media from '../styles/media'
import Phone from '../elements/phone'

import { ResponsiveBr } from './toolbox-section'

const Heading = styled.h2`
  margin: 0;
  font-family: sans-serif;
  font-size: 52px;
  font-weight: 500;
  color: #3a3a3a;

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 300ms;
  }

  ${media.desktop`
    font-size: 52px;
    line-height: 1.31;
    letter-spacing: -0.87px;
  `}

  ${media.phone`
    font-size: 26px;
    line-height: 1.3;
    letter-spacing: -0.5px;
  `}
`

const Subheading = styled.div`
  font-family: sans-serif;
  font-size: 17px;
  color: #3a3a3a;

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 400ms;
  }

  ${media.desktop`
    font-size: 17px;
    margin: 20px 0 0 0;
    line-height: 1.76;
    letter-spacing: -0.28px;
  `}

  ${media.phone`
    margin: 10px 0 0 0;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: -0.5px;
  `}
`

const PhoneContainer = styled.div`
  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 500ms;
  }
`

const HeadingContainer = styled.div`
  position: absolute;
  width: 100%;

  ${media.desktop`
    top: 240px;
  `}

  ${media.phone`
    top: 110px;
    text-align: center;
  `}
`

function Guide() {
  const [active, setActive] = useState(false)

  return (
    <SectionContainer background="#fafafa">
      <ResponsiveSection desktop={{ height: 1000 }} phone={{ height: 760 }}>
        <IntersectionObserver
          active={active}
          onActivate={() => setActive(true)}
        >
          <HeadingContainer>
            <CSSTransition
              in={active}
              classNames="fade"
              className="fade-enter"
              timeout={1000}
            >
              <Heading>
                어려운 <ResponsiveBr />
                해외여행 경비
                <br />
                함께 관리해요
              </Heading>
            </CSSTransition>
            <CSSTransition
              in={active}
              classNames="fade"
              className="fade-enter"
              timeout={1100}
            >
              <Subheading>
                해외여행 경비를 동행인과 함께
                <br />
                간편하게 관리하세요.
              </Subheading>
            </CSSTransition>
          </HeadingContainer>
        </IntersectionObserver>

        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={1200}
        >
          <PhoneContainer>
            <Responsive maxWidth={1141}>
              <Phone
                size="medium"
                src={withAssetPrefix('/images/img_05_screen_1@4x.png')}
                absolutePosition={{ top: '286px', right: '147px' }}
              />
              <Phone
                size="medium"
                src={withAssetPrefix('/images/img_05_screen_2@4x.png')}
                absolutePosition={{ top: '321px', right: '47px' }}
              />
            </Responsive>
            <Responsive minWidth={1142}>
              <Phone
                size="biggest"
                desktopSize="biggest"
                src={withAssetPrefix('/images/img_05_screen_1@4x.png')}
                absolutePosition={{ top: '152px', right: '190px' }}
              />
              <Phone
                size="biggest"
                desktopSize="biggest"
                src={withAssetPrefix('/images/img_05_screen_2@4x.png')}
                absolutePosition={{ top: '252px', right: '0' }}
              />
            </Responsive>
          </PhoneContainer>
        </CSSTransition>
      </ResponsiveSection>
    </SectionContainer>
  )
}

const GuideSection = memo(Guide)

export default GuideSection
