import { memo, useCallback, useMemo } from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import IntersectionObserver from '@titicaca/react-intersection-observer'
import { PublicHeader } from '@titicaca/public-header'
import {
  Container,
  StickyHeader as OriginalStickyHeader,
} from '@titicaca/core-elements'
import { CSSTransition } from 'react-transition-group'
import {
  useEventTrackingContext,
  useHistoryFunctions,
  useSessionAvailability,
  useUriHash,
} from '@titicaca/react-contexts'
import { FloatingButtonCTA } from '@titicaca/app-installation-cta'

import withAssetPrefix from '@/common/with-asset-prefix'
import { SideMenu } from '@/intro/header/side-menu'
import { MenuItem } from '@/intro/header/side-menu/type'

import InventoryBanner from '../inventory-banner'
import media from '../styles/media'
import { withHeaderContext } from '../elements/header-context-provider'

const HEADER_MAX_WIDTH = 1141

const BaseHeader = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${HEADER_MAX_WIDTH}px;
  margin: 0 auto;

  ${media.desktop`
    padding: 85px 42px 17px 42px;
  `}

  ${media.phone`
    padding: 44px 20px 0 17px;
  `}
`

const StickyHeader = styled(OriginalStickyHeader)`
  opacity: 0;
  transition: all 400ms ease-in-out;
  transform: translateY(-100%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-bottom: 1px solid var(--color-brightGray);

  /** TODO public header의 border를 제거합니다. TF 수정 이후 css props으로 조정해야 합니다. */
  & > div > div > div {
    border: none;
  }

  &.fade-enter-done {
    opacity: 1;
    transform: translateY(0%);
  }
`

const Logo = styled.div<{ backgroundImageSrc: string }>`
  display: inline-block;
  background-image: url(${({ backgroundImageSrc }) => backgroundImageSrc});
  background-repeat: no-repeat;
  vertical-align: top;

  ${media.desktop`
    width: 77px;
    height: 25px;
    background-size: 77px 25px;
  `}

  ${media.phone`
    width: 56px;
    height: 18px;
    background-size: 56px 18px;
  `}
`

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;

  ${media.phone`
    gap: 12px;
  `}
`

const LinkText = styled.a`
  display: flex;
  align-items: center;
  font-family: sans-serif;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  text-decoration: none;
  height: 24px;
  line-height: 20px;

  ${media.desktop`
    font-size: 17px;
  `}

  ${media.phone`
    font-size: 12px;
  `}
`

export const MenuButton = styled.button<{ hasNewNotification?: boolean }>`
  position: relative;
  width: 24px;
  height: 24px;
  background: no-repeat center/100%
    url('https://assets.triple.guide/images/ico_navi_menu@4x.png');
  margin: 0 8px;

  ${({ hasNewNotification }) =>
    hasNewNotification &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: -6px;
        right: -7px;
        width: 8px;
        height: 8px;
        background-color: #fd2e69;
        border-radius: 50%;
      }
    `}
`

const SIDE_MENU_HASH = 'SIDE_MENU_HASH'

const MENU_LINKS: MenuItem[] = [
  {
    type: 'link',
    label: '내 예약',
    href: '/my-bookings',
    eventAction: '내예약_선택',
  },
  {
    type: 'link',
    label: '도시별 여행정보',
    href: '/trips/intro',
    eventAction: '라운지홈_선택',
    tooltipDescription: '무료로 확인해 보세요!',
  },
  {
    type: 'link',
    label: 'AI 추천 맞춤일정',
    href: '/trips/promotion/customized-schedule',
    eventAction: '맞춤일정_선택',
  },
  {
    type: 'accordion',
    label: '여행상품',
    defaultOpen: true,
    subItems: [
      {
        type: 'link',
        label: '항공',
        href: '/air',
        eventAction: '항공_선택',
      },
      {
        type: 'link',
        label: '숙소',
        href: '/hotels',
        eventAction: '숙소_선택',
      },
      {
        type: 'link',
        label: '투어·티켓',
        href: '/tna',
        eventAction: '투어티켓_선택',
      },
    ],
  },
]

interface PreHeader {
  activeHeader: boolean
  activeSection9: boolean
  setActiveHeader(isIntersecting: boolean): void
}

function PreHeader({
  activeHeader,
  activeSection9,
  setActiveHeader,
}: PreHeader) {
  const { trackEvent } = useEventTrackingContext()
  const { push, back } = useHistoryFunctions()
  const uriHash = useUriHash()
  const sessionAvailable = useSessionAvailability()

  const onHeaderLogoClick = () => {
    trackEvent({ fa: { action: '헤더_트리플홈_선택' } })
  }

  const onMyBookingClick = () => {
    trackEvent({ fa: { action: '헤더_내예약_선택' } })
  }

  const onMenuButtonClick = useCallback(() => {
    push(SIDE_MENU_HASH)
    trackEvent({ fa: { action: '헤더_메뉴_선택' } })
  }, [trackEvent, push])

  const onSideMenuClose = useCallback(() => {
    trackEvent({ fa: { category: '메인메뉴', action: '닫기_선택' } })
    back()
  }, [trackEvent, back])

  const onTrackMenuEvent = useCallback(
    (eventAction?: string) => {
      if (eventAction) {
        trackEvent({ fa: { category: '메인메뉴', action: eventAction } })
      }
    },
    [trackEvent],
  )

  const MENUS = useMemo(
    () =>
      MENU_LINKS.map((menu) => ({
        ...menu,
        ...('subItems' in menu && {
          subItems: menu.subItems.map((subItem) => ({
            ...subItem,
            onClick: () => {
              onTrackMenuEvent(subItem.eventAction)
            },
          })),
        }),
        onClick: () => onTrackMenuEvent(menu.eventAction),
      })),
    [onTrackMenuEvent],
  )

  return (
    <>
      <CSSTransition
        appear
        in={!activeHeader && !activeSection9}
        classNames="fade"
        timeout={0}
      >
        <StickyHeader>
          <Container
            css={{
              maxWidth: HEADER_MAX_WIDTH,
              margin: '0 auto',
            }}
          >
            <PublicHeader
              disableAutoHide
              onLogoClick={onHeaderLogoClick}
              onClick={onMyBookingClick}
              deeplinkPath="deeplink" // DeeplinkComponent를 랜더링하기 위한 임의의 값입니다.
              DeeplinkComponent={() => (
                <MenuButton
                  onClick={onMenuButtonClick}
                  hasNewNotification={sessionAvailable}
                />
              )}
            />
          </Container>
        </StickyHeader>
      </CSSTransition>

      <InventoryBanner />

      <IntersectionObserver
        onChange={({ isIntersecting }) => {
          setActiveHeader(isIntersecting)
        }}
      >
        <BaseHeader>
          <Link href="/" passHref onClick={onHeaderLogoClick}>
            <Logo
              backgroundImageSrc={withAssetPrefix(
                '/images/img-main-logo-white@3x.png',
              )}
            />
          </Link>
          <LinkContainer>
            <LinkText
              rel="noopener"
              href="/my-bookings"
              onClick={onMyBookingClick}
            >
              내 예약
            </LinkText>
            <MenuButton
              onClick={onMenuButtonClick}
              hasNewNotification={sessionAvailable}
              css={{
                backgroundImage:
                  'url(https://assets.triple.guide/images/header/ico_navi_menu_white@4x.png)',
                margin: '-2px 0 0',
              }}
            />
          </LinkContainer>
        </BaseHeader>
      </IntersectionObserver>

      <SideMenu
        open={uriHash === SIDE_MENU_HASH}
        onClose={onSideMenuClose}
        menus={MENUS}
      />

      <CSSTransition in={!activeHeader} classNames="fade" timeout={0}>
        <FloatingButtonCTA
          appInstallLink={`https://triple.onelink.me/aZP6?pid=intro_web&af_dp=${encodeURIComponent(
            'triple:///main',
          )}`}
          fixed
          title="내 여행 동선, 한눈에 보고 싶다면"
          description="트리플에서 나만의 일정 짜기"
          trackEvent={trackEvent}
          trackEventParams={{
            onSelect: { ga: ['플로팅_설치하기_선택'] },
            onClose: { ga: ['플로팅_설치하기_닫기'] },
          }}
        />
      </CSSTransition>
    </>
  )
}

const Header = memo(PreHeader)

export default withHeaderContext(Header)
