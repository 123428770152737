import styled from 'styled-components'

import { MIN_DESKTOP_WIDTH, MAX_PHONE_WIDTH } from '../styles/media'

interface DeviceSizeProps {
  height: number
}

interface StylesProps {
  background?: string
  desktop?: DeviceSizeProps
  phone?: DeviceSizeProps
}

export const SectionContainer = styled.div<StylesProps>`
  position: relative;
  overflow: hidden;
  background: ${({ background }) => background || 'inherit'};
  background-size: cover;
  background-position: center;
`

export const ResponsiveSection = styled.div<StylesProps>`
  margin: 0 auto;
  position: relative;

  @media (min-width: ${MIN_DESKTOP_WIDTH}px) {
    width: 1040px;
    height: ${({ desktop }) =>
      desktop && desktop.height ? `${desktop.height}px` : 'auto'};
  }

  @media (max-width: ${MAX_PHONE_WIDTH}px) {
    max-width: 360px;
    height: ${({ phone }) =>
      phone && phone.height ? `${phone.height}px` : 'auto'};
  }
`
