import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import media from '../styles/media'

interface StylesProps {
  desktopSize?: string | number
  size?: string
  absolutePosition?: PositionProps
  centered?: boolean
  margin?: PositionProps
  src?: string
  display?: string
  floated?: string
  zIndex?: number
  backgroundImageSrc?: string
}

interface PhoneShape {
  frameStyle: FlattenSimpleInterpolation
  screenStyle: FlattenSimpleInterpolation
  notchStyle: FlattenSimpleInterpolation
}

interface PhoneSize {
  [key: string]: PhoneShape
}

const SIZES: PhoneSize = {
  massive: {
    frameStyle: css`
      width: 330px;
      height: 680px;
      border-radius: 48px;
      box-shadow: 0 13px 24px 0 rgba(0, 0, 0, 0.18),
        inset 0 -6px 9px 0 rgba(0, 0, 0, 0.08);
    `,
    screenStyle: css`
      width: 300px;
      height: 650px;
      top: 15px;
      left: 15px;
      border-radius: 33px;
      background-size: 300px 650px;
    `,
    notchStyle: css`
      width: 162px;
      height: 38px;
      left: 84px;
      border-radius: 18px;
    `,
  },
  biggest: {
    frameStyle: css`
      width: 290px;
      height: 598px;
      border-radius: 42px;
      box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.18),
        inset 0 -4px 6px 0 rgba(0, 0, 0, 0.08);
    `,
    screenStyle: css`
      width: 262px;
      height: 570px;
      top: 14px;
      left: 14px;
      border-radius: 20px;
      background-size: 262px 570px;
    `,
    notchStyle: css`
      width: 142px;
      height: 33px;
      left: 74px;
      border-radius: 16px;
    `,
  },
  big: {
    frameStyle: css`
      width: 231px;
      height: 476px;
      border-radius: 34px;
      box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.18),
        inset 0 -4px 6px 0 rgba(0, 0, 0, 0.08);
    `,
    screenStyle: css`
      width: 209px;
      height: 454px;
      top: 11px;
      left: 11px;
      border-radius: 23px;
      background-size: 209px 454px;
    `,
    notchStyle: css`
      width: 113px;
      height: 27px;
      left: 59px;
      border-radius: 13px;
    `,
  },
  large: {
    frameStyle: css`
      width: 221px;
      height: 456px;
      border-radius: 32px;
      box-shadow: 0 9px 16px 0 rgba(0, 0, 0, 0.18),
        inset 0 -4px 6px 0 rgba(0, 0, 0, 0.08);
    `,
    screenStyle: css`
      width: 202px;
      height: 437px;
      top: 9px;
      left: 10px;
      border-radius: 32px;
      background-size: 201px 436px;
    `,
    notchStyle: css`
      width: 109px;
      height: 25px;
      left: 56px;
      border-radius: 12px;
    `,
  },
  medium: {
    frameStyle: css`
      width: 165px;
      height: 340px;
      border-radius: 24px;
      box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.18),
        inset 0 -3px 5px 0 rgba(0, 0, 0, 0.08);
    `,
    screenStyle: css`
      width: 149px;
      height: 324px;
      top: 8px;
      left: 8px;
      border-radius: 16px;
      background-size: 149px 324px;
    `,
    notchStyle: css`
      width: 81px;
      height: 19px;
      left: 42px;
      border-radius: 9px;
    `,
  },
  small: {
    frameStyle: css`
      width: 120px;
      height: 246px;
      border-radius: 18px;
      box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.18),
        inset 0 -2px 3px 0 rgba(0, 0, 0, 0.08);
    `,
    screenStyle: css`
      width: 108px;
      height: 234px;
      top: 6px;
      left: 6px;
      border-radius: 18px;
      background-size: 108px 234px;
    `,
    notchStyle: css`
      width: 58px;
      height: 15px;
      left: 31px;
      border-radius: 7px;
    `,
  },
  smallest: {
    frameStyle: css`
      width: 112px;
      height: 230px;
      border-radius: 16px;
      box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.18),
        inset 0 -2px 3px 0 rgba(0, 0, 0, 0.08);
    `,
    screenStyle: css`
      width: 102px;
      height: 220px;
      top: 5px;
      left: 5px;
      border-radius: 16px;
      background-size: 102px 220px;
    `,
    notchStyle: css`
      width: 54px;
      height: 13px;
      left: 29px;
      border-radius: 6px;
    `,
  },
}

const PhoneFrame = styled.div<StylesProps>`
  background-color: #fafafa;

  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `}

  ${({ display }) =>
    display &&
    css`
      display: ${display};
    `}

  ${({ floated }) =>
    floated &&
    css`
      float: ${floated};
    `}

  ${({ margin }) =>
    margin &&
    css`
      margin-top: ${margin.top || 0}px;
      margin-bottom: ${margin.bottom || 0}px;
      margin-left: ${margin.left || 0}px;
      margin-right: ${margin.right || 0}px;
    `};

  ${({ absolutePosition }) =>
    absolutePosition
      ? css`
          position: absolute;
          top: ${absolutePosition.top !== undefined
            ? `${absolutePosition.top}`
            : 'auto'};
          bottom: ${absolutePosition.bottom !== undefined
            ? `${absolutePosition.bottom}`
            : 'auto'};
          left: ${absolutePosition.left !== undefined
            ? `${absolutePosition.left}`
            : 'auto'};
          right: ${absolutePosition.right !== undefined
            ? `${absolutePosition.right}`
            : 'auto'};
        `
      : css`
          position: relative;
        `};

  ${({ desktopSize }) =>
    media.desktop`${SIZES[desktopSize || 'massive'].frameStyle}`}
  ${({ size }) => media.phone`${SIZES[size || 'massive'].frameStyle}`}
  ${({ centered }) =>
    centered &&
    css`
      transform: translateX(-50%);
    `}

  ${({ centered }) =>
    centered &&
    css`
      transform: translateX(-50%);
    `};

  :after {
    content: '';
    position: absolute;
    top: 0;
    background-color: #fafafa;

    ${({ desktopSize }) =>
      media.desktop`${SIZES[desktopSize || 'massive'].notchStyle}`}
    ${({ size }) => media.phone`${SIZES[size || 'massive'].notchStyle}`}
  }
`

const PhoneScreen = styled.div<StylesProps>`
  background-image: url(${({ backgroundImageSrc }) => backgroundImageSrc});
  background-repeat: no-repeat;
  position: absolute;

  ${({ desktopSize }) =>
    media.desktop`${SIZES[desktopSize || 'massive'].screenStyle}`}
  ${({ size }) => media.phone`${SIZES[size || 'massive'].screenStyle}`}
`

interface PositionProps {
  top?: string
  bottom?: string
  left?: string
  right?: string
}

interface PhoneProps {
  desktopSize?: string | number
  size?: string
  absolutePosition?: PositionProps
  centered?: boolean
  margin?: PositionProps
  src?: string
  display?: string
  floated?: string
  zIndex?: number
}

export default function Phone({
  desktopSize,
  size,
  absolutePosition,
  centered,
  margin,
  src,
  display,
  floated,
  zIndex,
}: PhoneProps) {
  return (
    <PhoneFrame
      size={size}
      desktopSize={desktopSize}
      centered={centered}
      margin={margin}
      absolutePosition={absolutePosition}
      display={display}
      floated={floated}
      zIndex={zIndex}
    >
      <PhoneScreen
        desktopSize={desktopSize}
        size={size}
        backgroundImageSrc={src}
      />
    </PhoneFrame>
  )
}
