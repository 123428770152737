import { EventTrackingProvider } from '@titicaca/react-contexts'
import * as Sentry from '@sentry/browser'

import Intro from '@/intro'

export default function IntroPage() {
  return (
    <EventTrackingProvider
      pageLabel="트리플_소개"
      onError={(error) => {
        Sentry.captureException(error)
      }}
    >
      <Intro />
    </EventTrackingProvider>
  )
}
