import { memo, useState } from 'react'
import styled, { css } from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import withAssetPrefix from '@/common/with-asset-prefix'

import IntersectionObserver from '../elements/intersection-observer'
import { ResponsiveSection, SectionContainer } from '../elements/section'
import media, { MIN_DESKTOP_WIDTH, MAX_PHONE_WIDTH } from '../styles/media'

const Heading = styled.h2`
  font-family: sans-serif;
  font-weight: 500;
  margin: 0;
  color: #ffffff;
  text-align: center;
  opacity: 0;
  transform: translateY(5%);

  &.fade-enter-done {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out;
  }

  ${media.desktop`
    font-size: 52px;
    line-height: 68px;
    letter-spacing: -1px;
    padding-top: 150px;
  `}

  ${media.phone`
    font-size: 26px;
    line-height: 34px;
    letter-spacing: -0.5px;
    padding-top: 110px;
  `}
`

const Subheading = styled.div`
  font-family: sans-serif;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  opacity: 0;
  transform: translateY(5%);

  &.fade-enter-done {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 100ms;
  }

  ${media.desktop`
    font-size: 17px;
    margin: 20px 0 0 0;
    line-height: 30px;
    letter-spacing: -0.28px;
  `}

  ${media.phone`
    font-size: 15px;
    margin: 10px 0 0 0;
    line-height: 24px;
    letter-spacing: -0.5px;
  `}
`

export const ResponsiveBr = styled.br<{ phone?: boolean }>`
  ${({ phone }) =>
    phone
      ? css`
          ${media.desktop`
    display: none;
  `}
        `
      : css`
          ${media.phone`
    display: none;
  `}
        `}
`

const Button = styled.a<{ active: boolean }>`
  font-family: sans-serif;
  display: inline-block;
  text-align: center;
  transition: all 300ms ease-out 200ms;
  cursor: pointer;

  ${({ active }) =>
    active
      ? css`
          color: #3666ff;
          font-weight: bold;
        `
      : css`
          color: #ffffff;
        `}

  ${media.desktop`
    width: 110px;
    height: 50px;
    font-size: 17px;
    line-height: 50px;
    border-radius: 27px;
    letter-spacing: -0.3px;
  `}

  ${media.phone`
    width: 82.5px;
    height: 37.5px;
    font-size: 12.75px;
    line-height: 37.5px;
    border-radius: 20.25px;
    letter-spacing: -0.2px;
  `}
`

const ButtonsContainer = styled.div`
  text-align: center;
  position: relative;
  opacity: 0;
  transform: translateY(10px);

  &.fade-enter-done {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 200ms;
  }

  ${media.desktop`
    width: 550px;
    margin: 60px auto 0;
  `}

  ${media.phone`
    width: 412.5px;
    margin: 70px auto 0 30px;
    white-space: nowrap;
  `}
`

const Buttons = styled.div<{ currentPage: number }>`
  @media (max-width: ${MAX_PHONE_WIDTH}px) {
    ${({ currentPage }) => css`
      transition: all 300ms ease-in-out 200ms;
      transform: translateX(${-currentPage * 42.5}px);
    `}
  }
`

const ActiveButtonIndicator = styled.div<{ currentPage: number }>`
  position: absolute;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  transition: all 300ms ease-in-out 200ms;
  top: 0;
  z-index: -1;

  ${({ currentPage }) => css`
    @media (min-width: ${MIN_DESKTOP_WIDTH}px) {
      left: ${Math.max(0, currentPage * 110)}px;
    }

    @media (max-width: ${MAX_PHONE_WIDTH}px) {
      transform: translateX(${-currentPage * 42.5}px);
      left: ${Math.max(0, currentPage * 82.5)}px;
    }
  `}

  ${media.desktop`
    width: 110px;
    height: 50px;
    border-radius: 27px;
  `}

  ${media.phone`
    width: 82.5px;
    height: 37.5px;
    border-radius: 20.25px;
  `}
`

const CardsContainer = styled.div`
  white-space: nowrap;
  text-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  &.fade-enter-active {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-done {
    opacity: 1;
    transform: translateY(0%);
    transition: all 300ms ease-in-out 200ms;
  }

  ${media.desktop`
    margin-top: 62px;
    width: 425px;
  `}

  ${media.phone`
    margin-top: 48px;
    width: 211px;
  `}
`

const Card = styled.div<{ active: boolean; backgroundImageSrc: string }>`
  display: inline-block;
  background-image: url(${({ backgroundImageSrc }) => backgroundImageSrc});
  background-repeat: no-repeat;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
  position: absolute;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  transition: all 250ms ease-in-out 200ms;

  &.current-enter-done {
    opacity: 1;
  }

  &.current-exit-active {
    opacity: 0;
    transition: all 250ms ease-in-out 0;

    &.forward {
      transform: translateX(-60%);
    }

    &.backward {
      transform: translateX(-40%);
    }
  }

  ${media.desktop`
    width: 425px;
    height: 300px;
    background-size: 425px 300px;
    border-radius: 12px;
  `}

  ${media.phone`
    width: 213px;
    height: 150px;
    background-size: 213px 150px;
    border-radius: 6px;
  `}
`

const Pointer = styled.div<{ available: boolean }>`
  position: absolute;
  background-image: url(${withAssetPrefix(
    '/images/btn-06-arrow-r-over@3x.png',
  )});
  cursor: ${({ available }) => (available ? 'pointer' : 'default')};
  transition: all 300ms ease-in-out;
  opacity: 0.3;

  &:hover {
    opacity: ${({ available }) => (available ? '1' : '0.3')};
  }
`

const PointerLeft = styled(Pointer)`
  transform: scaleX(-1);

  ${media.desktop`
    top: 130px;
    left: -130px;
    width: 50px;
    height: 90px;
    background-size: 50px 90px;
  `}

  ${media.phone`
    top: 60px;
    left: -27px;
    width: 25px;
    height: 45px;
    background-size: 25px 45px;
  `}
`

const PointerRight = styled(Pointer)`
  ${media.desktop`
    top: 130px;
    right: -130px;
    width: 50px;
    height: 90px;
    background-size: 50px 90px;
  `}

  ${media.phone`
    top: 60px;
    right: -60px;
    width: 25px;
    height: 45px;
    background-size: 25px 45px;
  `}
`

const PAGES = [
  {
    label: '날씨',
    image: '/images/img-06-card-1@3x.png?20190802',
  },
  {
    label: '환율',
    image: '/images/img-06-card-2@3x.png?20190802',
  },
  {
    label: '길찾기',
    image: '/images/img-06-card-3@3x.png?20190802',
  },
  {
    label: '번역',
    image: '/images/img-06-card-4@3x.png?20190802',
  },
  {
    label: '시차계산',
    image: '/images/img-06-card-5@3x.png?20190802',
  },
]

function Toolbox() {
  const [active, setActive] = useState(false)
  const [currentPage, setCurrentPage] = useState(-1)
  const [forward, setForward] = useState(true)

  const setPage = (i: number) => {
    if (i !== currentPage) {
      setCurrentPage(i)
      setForward(i > currentPage)
    }
  }

  return (
    <SectionContainer
      background={`url(${withAssetPrefix(
        '/images/bg-section-06.svg',
      )}) #50e3c2`}
    >
      <ResponsiveSection desktop={{ height: 1000 }} phone={{ height: 700 }}>
        <IntersectionObserver
          active={active}
          onActivate={() => {
            setActive(true)
            setCurrentPage(0)
          }}
        >
          <CSSTransition in={active} classNames="fade" timeout={0}>
            <Heading>
              날씨. 환율. 길찾기
              <br />
              번역. 시차계산
            </Heading>
          </CSSTransition>
        </IntersectionObserver>

        <CSSTransition in={active} classNames="fade" timeout={0}>
          <Subheading>
            나의 위치 기반으로 제공되는
            <br />
            날씨, 환율, 길찾기 정보로
            <ResponsiveBr phone /> 더 쉬운 여행을 경험하세요.
          </Subheading>
        </CSSTransition>

        <CSSTransition in={active} classNames="fade" timeout={0}>
          <ButtonsContainer>
            <Buttons currentPage={currentPage}>
              {PAGES.map(({ label }, i) => (
                <Button
                  key={i}
                  active={currentPage === i}
                  onClick={() => setPage(i)}
                >
                  {label}
                </Button>
              ))}
            </Buttons>
            <ActiveButtonIndicator currentPage={currentPage} />
          </ButtonsContainer>
        </CSSTransition>

        <CSSTransition in={active} classNames="fade" timeout={0}>
          <CardsContainer>
            {PAGES.map(({ image }, i) => (
              <CSSTransition
                key={i}
                in={currentPage === i}
                classNames="current"
                className={`${forward ? 'forward' : 'backward'}`}
                timeout={{ enter: 0, exit: 500 }}
              >
                <Card
                  active={currentPage === i}
                  backgroundImageSrc={withAssetPrefix(image)}
                />
              </CSSTransition>
            ))}

            <PointerLeft
              available={currentPage > 0}
              onClick={() => setPage(Math.max(currentPage - 1, 0))}
            />
            <PointerRight
              available={currentPage < PAGES.length - 1}
              onClick={() =>
                setPage(Math.min(currentPage + 1, PAGES.length - 1))
              }
            />
          </CardsContainer>
        </CSSTransition>
      </ResponsiveSection>
    </SectionContainer>
  )
}

const ToolboxSection = memo(Toolbox)

export default ToolboxSection
