import { memo, useState } from 'react'
import styled from 'styled-components'
import Countup from 'react-countup'
import { CSSTransition } from 'react-transition-group'

import withAssetPrefix from '@/common/with-asset-prefix'

import IntersectionObserver from '../elements/intersection-observer'
import { ResponsiveSection, SectionContainer } from '../elements/section'
import media from '../styles/media'

interface Styles {
  backgroundImageSrc?: string
}

const ContentLogo = styled.div<Styles>`
  box-sizing: border-box;
  background-image: url(${({ backgroundImageSrc }) => backgroundImageSrc});
  background-repeat: no-repeat;
  text-align: center;
  font-family: sans-serif;
  color: rgba(58, 58, 58, 0.7);

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out;
  }

  ${media.desktop`
    position: absolute;
    top: 150px;
    width: 400px;
    height: 338px;
    background-size: 400px 338px;
    padding-top: 280px;
    font-size: 15px;
  `}
  ${media.phone`
    margin: 80px auto 20px auto;
    width: 200px;
    height: 169px;
    background-size: 200px 169px;
    padding-top: 137px;
    font-size: 10px;
  `}
`

const MetricsContainer = styled.div`
  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 300ms;
  }

  ${media.desktop`
    margin-left: 623px;
    padding-top: 150px;
  `}
  ${media.phone`
    text-align: center;
  `}
`

const MetricItem = styled.div`
  color: #3a3a3a;
  font-family: sans-serif;
  strong {
    font-weight: bold;
  }

  ${media.desktop`
    font-size: 36px;
    letter-spacing: -1px;
    margin-bottom: 31px;
  `}
  ${media.phone`
    font-size: 20px;
    letter-spacing: -0.5px;
    margin-bottom: 15px;
  `}
`

const AwardsContainer = styled.div`
  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 500ms;
  }

  white-space: nowrap;

  ${media.desktop`
    margin: 61px 0 140px 623px;
  `}

  ${media.phone`
    width: 300px;
    margin: 55px auto 80px auto;
  `}
`

const AwardItem = styled.div<Styles>`
  display: inline-block;
  font-family: sans-serif;
  background-image: url(${({ backgroundImageSrc }) => backgroundImageSrc});
  background-position: left top;
  background-repeat: no-repeat;
  color: rgba(58, 58, 58, 0.8);
  font-weight: bold;

  ${media.desktop`
    background-size: 54px 54px;
    height: 54px;
    padding: 5px 0 5px 62px;
    font-size: 14px;
    line-height: 22px;
    margin-right: 39px;
  `}
  ${media.phone`
    background-size: 39px 39px;
    height: 39px;
    padding: 4px 0 4px 45px;
    font-size: 10px;
    line-height: 15px;

    &:first-child {
      margin-right: 28.5px;
    }
  `}
`

function Statistic() {
  const [active, setActive] = useState(false)

  return (
    <SectionContainer>
      <ResponsiveSection>
        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={700}
        >
          <ContentLogo
            backgroundImageSrc={withAssetPrefix('/images/img-02-triple@3x.png')}
          >
            2022년 12월 기준
          </ContentLogo>
        </CSSTransition>

        <IntersectionObserver
          active={active}
          onActivate={() => setActive(true)}
        >
          <CSSTransition
            in={active}
            classNames="fade"
            className="fade-enter"
            timeout={700}
          >
            <MetricsContainer>
              <MetricItem>
                <strong>
                  <Countup start={active ? 1 : 770} end={780} separator="," />만
                  명
                </strong>
                의 여행자
              </MetricItem>
              <MetricItem>
                <strong>
                  <Countup start={active ? 1 : 100} end={110} separator="," />만
                  개
                </strong>
                의 여행 리뷰
              </MetricItem>
              <MetricItem>
                <strong>
                  <Countup start={active ? 1 : 580} end={590} separator="," />만
                  개
                </strong>
                의 여행 일정
              </MetricItem>
            </MetricsContainer>
          </CSSTransition>
        </IntersectionObserver>

        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={1200}
        >
          <AwardsContainer>
            <AwardItem
              backgroundImageSrc={withAssetPrefix(
                '/images/img-02-badge-google@3x.png',
              )}
            >
              2018 구글 플레이스토어
              <br />
              올해의 앱 최우수상 수상
            </AwardItem>
            <AwardItem
              backgroundImageSrc={withAssetPrefix(
                '/images/img-02-badge-apple@4x.png',
              )}
            >
              2018 애플 앱스토어
              <br />
              오늘의 여행앱 선정
            </AwardItem>
          </AwardsContainer>
        </CSSTransition>
      </ResponsiveSection>
    </SectionContainer>
  )
}

const StatisticSection = memo(Statistic)

export default StatisticSection
