import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container, Text } from '@titicaca/core-elements'

import { fetchInventories } from './service'
import { InventoryItem } from './types'

const CouponContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #242d38;
`

const CouponButton = styled.a`
  align-self: center;
  text-align: center;

  img {
    vertical-align: middle;
    width: 78px;
    height: 26px;
  }
`

function InventoryBanner() {
  const [inventoryItem, setInventoryItem] = useState<InventoryItem>()

  useEffect(() => {
    async function handleInventoryBanner() {
      const itemName = 'upper-gnb-web'
      const response = await fetchInventories(itemName)

      if (response.ok) {
        const { items } = response.parsedBody

        setInventoryItem(items[0])
      }
    }

    void handleInventoryBanner()
  }, [])

  return inventoryItem ? (
    <CouponContainer css={{ width: '100%', height: 50, padding: '12px 18px' }}>
      <Text size="tiny" color="white" inlineBlock ellipsis>
        {inventoryItem.text}
      </Text>
      <CouponButton href={inventoryItem.target} target="_blank">
        <img src={inventoryItem.image} alt="coupon download" />
      </CouponButton>
    </CouponContainer>
  ) : null
}

export default InventoryBanner
