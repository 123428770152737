import { useState, memo } from 'react'
import styled from 'styled-components'
import { Responsive } from '@titicaca/core-elements'
import { CSSTransition } from 'react-transition-group'

import withAssetPrefix from '@/common/with-asset-prefix'

import { ResponsiveSection, SectionContainer } from '../elements/section'
import IntersectionObserver from '../elements/intersection-observer'
import media from '../styles/media'
import Phone from '../elements/phone'
import { Download } from '../elements/market-links'

const HeadingSectionContainer = styled(SectionContainer)`
  position: relative;
  background-image: url(${withAssetPrefix('/images/img-01-bg.png')});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`

const HeadingContent = styled.div`
  position: absolute;
  width: 100%;

  ${media.desktop`
    top: 220px;
  `}

  ${media.phone`
    top: 120px;
    text-align: center;
  `}
`

const Heading1 = styled.h1`
  font-family: sans-serif;
  font-weight: bold;
  margin: 0 0 0 -4px;
  color: #fff;

  &.fade-enter {
    opacity: 0;
    transform: translateY(5%);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 600ms ease-in-out;
  }

  ${media.desktop`
    font-size: 90px;
    line-height: 1.1;
    letter-spacing: -3px;
  `}

  ${media.phone`
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: -1.5px;
  `}
`

const Subheading = styled.p`
  font-family: sans-serif;
  letter-spacing: 0;

  color: #fff;
  font-weight: 500;

  &.fade-enter {
    opacity: 0;
    transform: translateY(5%);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 900ms ease-in-out;
  }

  strong {
    font-weight: bold;
  }

  ${media.desktop`
    margin: 30px 0 0 1px;
    font-size: 28px;
  `}

  ${media.phone`
    font-size: 15px;
    line-height: 1.6;
    text-align: center;
    white-space: pre;
  `}
`
const MarketLinksContainer = styled.div`
  margin-top: 60px;

  a:first-child {
    margin-right: 8px;
  }

  &.fade-enter {
    opacity: 0;
    transform: translateY(5%);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 900ms ease-in-out;
  }

  ${media.phone`
    display: none;
  `}
`

const PhoneContainer = styled.div`
  &.fade-enter {
    opacity: 0;
    transform: translateY(5%);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out;
  }
`

function Heading() {
  const [active, setActive] = useState(false)

  return (
    <HeadingSectionContainer>
      <ResponsiveSection desktop={{ height: 860 }} phone={{ height: 700 }}>
        <IntersectionObserver
          active={active}
          onActivate={() => setActive(true)}
        >
          <HeadingContent>
            <CSSTransition
              in={active}
              classNames="fade"
              className="fade-enter"
              timeout={600}
            >
              <Heading1>
                나를 아는 여행앱
                <br />
                트리플
              </Heading1>
            </CSSTransition>
            <CSSTransition
              in={active}
              classNames="fade"
              className="fade-enter"
              timeout={900}
            >
              <Subheading>예약부터 일정까지 여행이 더 쉬워집니다.</Subheading>
            </CSSTransition>
            <CSSTransition
              in={active}
              classNames="fade"
              className="fade-enter"
              timeout={900}
            >
              <MarketLinksContainer>
                <Download />
              </MarketLinksContainer>
            </CSSTransition>
          </HeadingContent>
        </IntersectionObserver>

        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={700}
        >
          <PhoneContainer>
            <Responsive maxWidth={1141}>
              <Phone
                size="big"
                centered
                src={withAssetPrefix('/images/img_01_screen_3_520@3x.png')}
                absolutePosition={{
                  top: '300px',
                  left: '50%',
                }}
              />
            </Responsive>
            <Responsive minWidth={1142}>
              <Phone
                size="massive"
                src={withAssetPrefix('/images/img_01_screen_3_520@3x.png')}
                absolutePosition={{ top: '229px', right: '0' }}
              />
            </Responsive>
          </PhoneContainer>
        </CSSTransition>
      </ResponsiveSection>
    </HeadingSectionContainer>
  )
}

const HeadingSection = memo(Heading)

export default HeadingSection
