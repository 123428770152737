import { memo } from 'react'
import styled from 'styled-components'
import IntersectionObserver from '@titicaca/react-intersection-observer'
import { useEventTrackingContext } from '@titicaca/react-contexts'

import withAssetPrefix from '@/common/with-asset-prefix'

import media from '../styles/media'
import { ResponsiveSection, SectionContainer } from '../elements/section'
import { withHeaderContext } from '../elements/header-context-provider'
import { AppStore, GooglePlay } from '../elements/market-links'

const Heading = styled.h2`
  font-family: sans-serif;
  color: rgba(255, 255, 255, 0.96);
  text-align: center;
  ${media.desktop`
    font-weight: 500;  
    padding-top: 419px;
    font-size: 29px;
  `}

  ${media.phone`
    font-weight: 500;
    padding-top: 223px;
    font-size: 20px;
  `}
`

const Subheading = styled.div`
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);

  ${media.desktop`
    margin-top: 11px;
    font-size: 18px;
    line-height: 27px;
  `}

  ${media.phone`
    margin-top: 5px;
    font-size: 15px;
    line-height: 22px;
  `}
`

const MarketLinksContainer = styled.div`
  text-align: center;

  ${media.desktop`
    margin-top: 30px;

    a:first-child {
      margin-right: 8px;
    }
  `}

  ${media.phone`
    margin-top: 20px;

    a:first-child {
      margin-right: 6px;
    }
  `}
`

const Logo = styled.img`
  position: absolute;

  ${media.desktop`
    width: 77px;
    height: 25px;
    top: 84px;
    left: 154px;
  `}

  ${media.phone`
    width: 56px;
    height: 18px;
    top: 29px;
    left: 33px;
  `}
`

const IconsContainer = styled.div`
  position: absolute;

  ${media.desktop`
    top: 80px;
    right: 150px;
  `}

  ${media.phone`
    top: 30px;
    right: 30px;
  `}

  * {
    margin-left: 10px;

    &:first-child {
      margin-left: 0px;
    }
  }
`

const SocialIcon = styled.img`
  ${media.desktop`
    width: 34px;
    height: 34px;
  `}

  ${media.phone`
    width: 22px;
    height: 22px;
  `}
`
interface FooterProps {
  setActiveSection9(isIntersecting: boolean): void
}

function Footer({ setActiveSection9 }: FooterProps) {
  const { trackEvent } = useEventTrackingContext()
  return (
    <IntersectionObserver
      onChange={({ isIntersecting }) => {
        setActiveSection9(isIntersecting)
      }}
    >
      <SectionContainer background="rgba(35, 51, 50, 0.96)">
        <ResponsiveSection desktop={{ height: 1000 }} phone={{ height: 560 }}>
          <Heading>
            나를 아는 여행앱, 트리플
            <Subheading>예약부터 일정까지 여행이 더 쉬워집니다.</Subheading>
          </Heading>

          <MarketLinksContainer>
            <GooglePlay />
            <AppStore />
          </MarketLinksContainer>
        </ResponsiveSection>

        <Logo src={withAssetPrefix('/images/img-main-logo-white@3x.png')} />

        <IconsContainer>
          <a
            href="https://post.naver.com/my.naver?memberNo=37551826"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              trackEvent({
                ga: ['포스트_콘텐츠_보기'],
              })
            }}
          >
            <SocialIcon
              src={withAssetPrefix('/images/btn_social_naverpost@3x.png')}
            />
          </a>
          <a
            href="https://www.facebook.com/triple.kr/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              trackEvent({
                ga: ['페이스북_콘텐츠_보기'],
              })
            }}
          >
            <SocialIcon src={withAssetPrefix('/images/btn-social-fb@3x.png')} />
          </a>
          <a
            href="https://www.instagram.com/triple.kr/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              trackEvent({
                ga: ['인스타그램_콘텐츠_보기'],
              })
            }}
          >
            <SocialIcon
              src={withAssetPrefix('/images/btn-social-insta@3x.png')}
            />
          </a>
          <a
            href="https://blog.naver.com/triple-kr"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              trackEvent({
                ga: ['블로그_콘텐츠_보기'],
              })
            }}
          >
            <SocialIcon
              src={withAssetPrefix('/images/btn-social-blog@3x.png')}
            />
          </a>
          <a
            href="https://brunch.co.kr/@triple#articles"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              trackEvent({
                ga: ['브런치_콘텐츠_보기'],
              })
            }}
          >
            <SocialIcon
              src={withAssetPrefix('/images/btn-social-brunch@3x.png')}
            />
          </a>
        </IconsContainer>
      </SectionContainer>
    </IntersectionObserver>
  )
}

const FooterSection = memo(Footer)

export default withHeaderContext(FooterSection)
