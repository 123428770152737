import { useEffect } from 'react'
import { GlobalStyle } from '@titicaca/core-elements'
import { useEventTrackingContext } from '@titicaca/react-contexts'
import Head from 'next/head'

import { DEFAULT_PAGE_TITLE, WEB_URL_BASE } from '@/common/env'

import { HeaderContextProvider } from './elements/header-context-provider'
import Header from './header'
import HeadingSection from './section/heading-section'
import StatisticSection from './section/statistic-section'
import ReservationSection from './section/reservation-section'
import RegionSection from './section/region-section'
import GuideSection from './section/guide-section'
import ToolboxSection from './section/toolbox-section'
import TripSection from './section/trip-section'
import ReviewSection from './section/review-section'
import TravelersClubSection from './section/travelers-club-section'
import FooterSection from './section/footer-section'
import Footer from './footer'

const SCHEMA_ORG_METADATA = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: DEFAULT_PAGE_TITLE,
  url: WEB_URL_BASE,
  sameAs: [
    'https://blog.naver.com/triple-kr',
    'https://post.naver.com/triple-kr',
    'https://instagram.com/triple.kr',
    'https://www.youtube.com/@triple_kr',
  ],
}

export default function Intro() {
  const { trackScreen } = useEventTrackingContext()

  useEffect(() => {
    trackScreen('/intro', '트리플_소개')
  }, [trackScreen])

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(SCHEMA_ORG_METADATA),
          }}
        />
      </Head>
      <GlobalStyle />
      <HeaderContextProvider>
        <Header />
        <HeadingSection />
        <StatisticSection />
        <ReservationSection />
        <TripSection />
        <GuideSection />
        <ReviewSection />
        <RegionSection />
        <ToolboxSection />
        <TravelersClubSection />
        <FooterSection />
        <Footer />
      </HeaderContextProvider>
    </>
  )
}
