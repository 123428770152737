import { ReactElement } from 'react'
import ReactIntersectionObserver from '@titicaca/react-intersection-observer'

interface Props {
  active: boolean
  onActivate(): void
  children?: ReactElement | null
}

export default function IntersectionObserver({
  active,
  onActivate,
  children,
}: Props) {
  return (
    <ReactIntersectionObserver
      onChange={
        active
          ? () => {}
          : ({ isIntersecting }) => isIntersecting && onActivate()
      }
    >
      {children}
    </ReactIntersectionObserver>
  )
}
