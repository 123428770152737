import { memo } from 'react'
import styled from 'styled-components'
import { AwardFooter } from '@titicaca/footer'

import media from './styles/media'
import { withHeaderContext } from './elements/header-context-provider'

const Separator = styled.div`
  ${media.phone`
    height: 54px;
    background-color: #fafafa;
  `}
`

function PreFooter() {
  return (
    <>
      <AwardFooter />
      <Separator />
    </>
  )
}

const FooterPage = memo(PreFooter)

export default withHeaderContext(FooterPage)
