import { memo, useState } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import withAssetPrefix from '@/common/with-asset-prefix'

import IntersectionObserver from '../elements/intersection-observer'
import { ResponsiveSection, SectionContainer } from '../elements/section'
import media from '../styles/media'

import { ResponsiveBr } from './toolbox-section'

const Heading = styled.h2`
  font-family: sans-serif;
  font-weight: 500;
  line-height: 1.31;
  color: #3a3a3a;

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 300ms;
  }

  ${media.desktop`
    position: absolute;
    margin: 0;
    top: 255px;
    font-size: 52px;
    letter-spacing: -1px;
  `}

  ${media.phone`
    padding-top: 110px;
    font-size: 26px;
    line-height: 34px;
    letter-spacing: -0.5px;
    text-align: center;
  `}
`

const Subheading = styled.div`
  font-family: sans-serif;
  color: #3a3a3a;

  ${media.desktop`
    font-size: 17px;
    line-height: 1.76;
    letter-spacing: -0.3px;
    margin: 20px 0 0 0;
  `}

  ${media.phone`
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: -0.5px;
    text-align: center;
    margin: 10px 0 0 0;
  `}
`

const Guam = styled.img`
  position: absolute;

  ${media.desktop`
    width: 160px;
    height: 160px;
    top: 343px;
    right: 438px;
  `}

  ${media.phone`
    width: 80px;
    height: 80px;
    top: 386px;
    left: 50%;
    margin-left: -195px;
  `}

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 700ms;
  }
`

const Singapore = styled.img`
  position: absolute;

  ${media.desktop`
    width: 140px;
    height: 140px;
    top: 182px;
    right: 315px;
  `}

  ${media.phone`
    width: 70px;
    height: 70px;
    top: 305.5px;
    left: 50%;
    margin-left: -123.5px;
  `}

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 650ms;
  }
`

const Danang = styled.img`
  position: absolute;

  ${media.desktop`
    width: 200px;
    height: 200px;
    top: 151px;
    right: 51px;
  `}

  ${media.phone`
    width: 100px;
    height: 100px;
    top: 290px;
    left: 50%;
    margin-left: -21.5px;
  `}

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 500ms;
  }
`

const Taipei = styled.img`
  position: absolute;

  ${media.desktop`
    width: 150px;
    height: 150px;
    top: 173px;
    right: -142px;
  `}

  ${media.phone`
    width: 75px;
    height: 75px;
    top: 301px;
    left: 50%;
    margin-left: 100px;
  `}

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 750ms;
  }
`

const Tokyo = styled.img`
  position: absolute;

  ${media.desktop`
    width: 200px;
    height: 200px;
    top: 346px;
    right: 204px;
  `}

  ${media.phone`
    width: 100px;
    height: 100px;
    top: 387.5px;
    left: 50%;
    margin-left: -98px;
  `}

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 600ms;
  }
`

const Bangkok = styled.img`
  position: absolute;

  ${media.desktop`
    width: 220px;
    height: 220px;
    top: 368px;
    right: -67px;
  `}

  ${media.phone`
    width: 110px;
    height: 110px;
    top: 398.5px;
    left: 50%;
    margin-left: 27.5px;
  `}

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 750ms;
  }
`

const Osaka = styled.img`
  position: absolute;

  ${media.desktop`
    width: 200px;
    height: 200px;
    top: 323px;
    right: -300px;
  `}

  ${media.phone`
    width: 100px;
    height: 100px;
    top: 376px;
    left: 50%;
    margin-left: 154px;
  `}

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 650ms;
  }
`

const London = styled.img`
  position: absolute;

  ${media.desktop`
    width: 130px;
    height: 130px;
    top: 523px;
    right: 554px;
  `}

  ${media.phone`
    width: 65px;
    height: 65px;
    top: 476px;
    left: 50%;
    margin-left: -238px;
  `}

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 750ms;
  }
`

const Paris = styled.img`
  position: absolute;

  ${media.desktop`
    width: 170px;
    height: 170px;
    top: 561px;
    right: 339px;
  `}

  ${media.phone`
    width: 85px;
    height: 85px;
    top: 495px;
    left: 50%;
    margin-left: -150.5px;
  `}

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 650ms;
  }
`

const Jeju = styled.img`
  position: absolute;

  ${media.desktop`
    width: 200px;
    height: 200px;
    top: 577px;
    right: 90px;
  `}

  ${media.phone`
    width: 100px;
    height: 100px;
    top: 503px;
    left: 50%;
    margin-left: -41px;
  `}

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 800ms;
  }
`

const Busan = styled.img`
  position: absolute;

  ${media.desktop`
    width: 170px;
    height: 170px;
    top: 568px;
    right: -200px;
  `}

  ${media.phone`
    width: 85px;
    height: 85px;
    top: 498.5px;
    left: 50%;
    margin-left: 119px;
  `}

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 600ms;
  }
`

const Gangwon = styled.img`
  position: absolute;

  ${media.desktop`
    width: 120px;
    height: 120px;
    top: 717px;
    right: -42px;
  `}

  ${media.phone`
    width: 60px;
    height: 60px;
    top: 573px;
    left: 50%;
    margin-left: 65px;
  `}

  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 700ms ease-in-out 800ms;
  }
`

function Region() {
  const [active, setActive] = useState(false)

  return (
    <SectionContainer background="#fafafa">
      <ResponsiveSection desktop={{ height: 1000 }} phone={{ height: 700 }}>
        <IntersectionObserver
          active={active}
          onActivate={() => setActive(true)}
        >
          <CSSTransition
            in={active}
            classNames="fade"
            className="fade-enter"
            timeout={1000}
          >
            <Heading>
              전 세계 <ResponsiveBr />
              최신 여행 정보를
              <br />
              모두 모았어요
              <Subheading>
                가장 최신의 도시별 가이드로
                <br />
                안심할 수 있는 여행을 계획하세요.
              </Subheading>
            </Heading>
          </CSSTransition>
        </IntersectionObserver>

        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={1400}
        >
          <Guam src={withAssetPrefix('/images/img_99_guam@4x.png')} />
        </CSSTransition>
        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={1350}
        >
          <Singapore src={withAssetPrefix('/images/img_99_singapore@4x.png')} />
        </CSSTransition>
        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={1200}
        >
          <Danang src={withAssetPrefix('/images/img_99_danang@4x.png')} />
        </CSSTransition>
        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={1450}
        >
          <Taipei src={withAssetPrefix('/images/img_99_taipei@4x.png')} />
        </CSSTransition>
        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={1350}
        >
          <Tokyo src={withAssetPrefix('/images/img_99_tokyo@4x.png')} />
        </CSSTransition>
        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={1450}
        >
          <Bangkok src={withAssetPrefix('/images/img_99_bangkok@4x.png')} />
        </CSSTransition>
        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={1350}
        >
          <Osaka src={withAssetPrefix('/images/img_03_osaka@4x.png')} />
        </CSSTransition>
        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={1450}
        >
          <London src={withAssetPrefix('/images/img_99_london@4x.png')} />
        </CSSTransition>
        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={1350}
        >
          <Paris src={withAssetPrefix('/images/img_99_paris@4x.png')} />
        </CSSTransition>
        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={1500}
        >
          <Jeju src={withAssetPrefix('/images/img_99_jeju@4x.png')} />
        </CSSTransition>
        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={1300}
        >
          <Busan src={withAssetPrefix('/images/img_03_busan@4x.png')} />
        </CSSTransition>
        <CSSTransition
          in={active}
          classNames="fade"
          className="fade-enter"
          timeout={1500}
        >
          <Gangwon src={withAssetPrefix('/images/img_03_gangwon@4x.png')} />
        </CSSTransition>
      </ResponsiveSection>
    </SectionContainer>
  )
}

const RegionSection = memo(Region)

export default RegionSection
